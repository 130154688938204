
.nav-inner-container {
    width: 100%;
    max-width: 1400px;
}

.dashboard-layout {
    z-index: 1;
    position: relative;
}
.page-background  {
    z-index: 0;
    position: fixed;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    opacity: 0.3;
}
.main-nav {
    z-index: 1;
    position: relative;
}
.main-content {
    z-index: 1;
    position: relative;
}


